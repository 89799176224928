import moment from 'moment';
import React,{ useState, useEffect } from 'react'; 
import numeral from 'numeral';
import FracTank from "../images/trucks/FracTank.svg";
import NATruck from "../images/yard_assets/image-placeholder.svg";
import MiniTank from "../images/trucks/MiniTank.svg";
import RollOffTank from "../images/trucks/RollOffTank.svg";
import SteelTank from "../images/trucks/SteelTank.svg";
import VaccumBox from "../images/trucks/VaccumBox.svg";
import VerticalTank from "../images/trucks/VerticalTank.svg";
import Bulkitems from "../images/trucks/Bulkitems.svg";
import Dewateringbox from "../images/trucks/Dewateringbox.svg";
import polytank from "../images/trucks/polytank.svg";
import Pump from "../images/trucks/Pump.svg";
// import Geocode from "react-geocode";


export const renderImage = (types) => {
   
    switch (types) {
        case 'Mini Tank':
            return MiniTank;
        case 'Vaccum Box':
            return VaccumBox;
        case 'Frac Tank':
            return FracTank
        case 'Roll Top':
            return RollOffTank;
        case 'Railcar':
            return VerticalTank;
        case  'Pumps':
            return Pump;
        case  'Poly Tank':
            return polytank;
        case 'Bulk (Berm, Hoses, Protector, Misc.)':
            return Bulkitems;
        case 'Vertical Frac':
            return VerticalTank;
        case 'Dewatering Box':
            return Dewateringbox;
        case 'Steel Tank Trailer':
            return SteelTank;
        
        default:
            return NATruck

    }
}
export  const  IndustryType = ()=> {
    if(typeof window !== 'undefined'&&localStorage.getItem('data')){
    const tabParam =  JSON.parse(localStorage.getItem('data'))
    const industryTypeArr=  tabParam.TabParams.filter((item)=>item.param_value===tabParam.industry_type)
   const industry_type= industryTypeArr[0]?.param_name
   return industry_type
    }
}
export function TimeDifference(startTime,endTime){
    const sMoment = moment(startTime?.length <= 13 ? parseInt(startTime):startTime).format('MM/DD/YYYY')
    const sp = moment(startTime?.length <= 13 ? parseInt(startTime):startTime).format('HH:mm')
    const start_time = new Date(moment(`${sMoment} ${sp}`).format('MM/DD/yyyy HH:mm'))
    const eMoment = moment(endTime?.length <= 13 ? parseInt(endTime):endTime).format('MM/DD/YYYY')
    const ep = moment(endTime?.length <= 13 ? parseInt(endTime):endTime).format('HH:mm')
    const end_time = new Date(moment(`${eMoment} ${ep}`).format('MM/DD/yyyy HH:mm'))
  
    const time_df = moment.utc(moment(end_time, "DD/MM/YYYY HH:mm:ss").diff(moment(start_time, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
    const splittedTime = time_df.split(':')
    const date1 = new Date(start_time);
    const date2 = new Date(end_time);
    const diffInMs = Math.abs(date2 - date1);
    const days = (diffInMs / (1000 * 60 * 60 * 24));
    const splitDaysStr = (days.toString()).split('.')
    const splitDays = parseInt(splitDaysStr[0])
  
    if(time_df){
    
        if(splitDays <= 0){
            return `${splittedTime[0]} HOURS ${splittedTime[1]} MINS`;
        }else{
          return `${splitDays} DAY ${splittedTime[0]} HOURS ${splittedTime[1]} MINS`;
        }
        
    }
  
  
}






export function toTimestamp(date) {
    let strDate = (new Date(date));
    let timestamp = new Date(strDate).getTime() / 1000;
    return parseInt(timestamp);
}
export const ChartMonthlyColor=()=>{
    return ['#ffd237','#ffb121','#fe892f','#ffd237','#146391','#6945a2','#9b254f','#ab2356','#6dd4ad','#64d0db','#35b1e4','#1d77b1']
}
export function getFullDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${month}/${day}/${year}`
}

export function getCurrencySymbols(code) {
    let symbol = '';
    switch (code) {
        case 'USD':
            symbol = '$';
            break;
        case 'SGD':
            symbol = 'S$';
            break;
        default:
            symbol = '$';
    }
    return symbol;
}

export function getFormattedDocumentType(name) {
    let formattedName = '';
    switch (name) {
        case 'TITLE_DOCUMENT':
            formattedName = 'TITLE';
            break;
        case 'INSURANCE_DOCUMENT':
            formattedName = 'INSURANCE';
            break;
        case 'REGISTRATION_DOCUMENT':
            formattedName = 'REGISTRATION';
            break;
        case 'INSPECTION_CERTIFICATE':
            formattedName = 'INSPECTION CERTIFICATE';
            break;
        case 'MILEAGE_DOCUMENT':
            formattedName = 'MILEAGE';
            break;
        case 'GAS_FILL_UP_DOCUMENT':
            formattedName = 'GAS FILL UP';
            break;
        case 'EXPENSE_DOCUMENT':
            formattedName = 'EXPENSE';
            break;
        default:
            formattedName = '';
    }
    return formattedName;
}

export function getDocumentName(documentType) {
    let documentName = '';
    switch (documentType.value) {
        case 1:
            documentName = 'TITLE';
            break;
        case 2:
            documentName = 'INSURANCE';
            break;
        case 3:
            documentName = 'REGISTRATION';
            break;
        case 4:
            documentName = 'INSPECTION';
            break;
        case 5:
            documentName = 'MILEAGE';
            break;
        case 6:
            documentName = 'GAS FILL UP';
            break;
        case 7:
            documentName = 'EXPENSE';
            break;
        default:
            documentName = documentType;
    }
    return documentName;
}

export function getFormattedName(name) {
    let formattedName = '';
    switch (name) {
        case 'FLEET_MANAGER':
            formattedName = 'Fleet Manager';
            break;
        case 'OPERATOR':
            formattedName = 'Operator';
            break;
        case 'ADMIN':
            formattedName = 'Admin';
            break;
        case 'OTHERS':
            formattedName = 'Others';
            break;
        default:
            formattedName = name;
    }
    return formattedName;
}

export function dateFormatter(seconds) {
    let formattedDate = moment(seconds * 1000).format('DD MMM YY')
    return formattedDate;
}

export function getOptionsFromDocumentType(option) {
    let optionToShow = '';
    switch (option) {
        case 'MILEAGE':
            optionToShow = 'Update Miles';
            break;
        case 'GAS':
            optionToShow = 'Update Gas';
            break;
        case 'EXPENSE':
            optionToShow = 'Update Expense';
            break;
        default:
            optionToShow = '';
    }
    return optionToShow;
}
export function usaFormatNumber(phone){
    if(phone){
    if(phone?.length===10){
     return "("+phone.slice(0,3)+")"+phone.slice(3,6)+"-"+phone.slice(6,10)
    }else if(phone?.length>10){
      return "("+phone.slice(2,5)+")"+phone.slice(5,8)+"-"+phone.slice(8,12)
    }else if(phone?.length===0){
      return "Null"
    }else{
      return phone
    }}else{
        return "NA"
    }
}
export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }else if(phoneNumberString){
        return phoneNumberString
    }else{
        return null
    }
  }

  
export function dateDiff(timestamp) {
    var finalDiff = '';
    let now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss"); //todays date
    let end1 = moment(timestamp); // another date
    let end = moment(new Date(end1)).format("DD/MM/YYYY HH:mm:ss");
    let ms = moment(end, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
    let d = moment.duration(ms);
    let r = {
        months: Math.floor(d.asMonths()),
        weeks: Math.floor(d.asWeeks()) - (4 * Math.floor(d.asMonths())),
        days: Math.floor(d.asDays()) - (7 * Math.floor(d.asWeeks())),
        hours: Math.floor(d.asHours()) - (24 * Math.floor(d.asDays())),
        minutes: (Math.floor(d.asMinutes()) / 60).toFixed(0),
    }
    Object.keys(r).forEach(function (key) {
    
        if (r[key] && r[key] > 0) {
            finalDiff += ` ${r[key]} ${key}`;
        }
    });
    return finalDiff;
}

export function getStatusColour(status) {
    let color = '';
    switch (status) {
        case 'Red':
            color = '#ff0000';
            break;
        case 'Green':
            color = '#32CD32';
            break;
        case 'Amber':
            color = '#FFA500';
            break;
        default:
            color = '#32CD32';
            break;
    }
    return color;
}

export function mapDateToDay(date) {
    let finalDay = date;
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    let newDate = new Date(date);
    let dayForDate = newDate.getDate();
    let monthForDate = newDate.getMonth() + 1;
    let yearForDate = newDate.getFullYear();

    if (year == yearForDate) {
        if (month == monthForDate) {
            if (day == dayForDate) {
                finalDay = 'Today';
            } else if (day + 1 == dayForDate) {
                finalDay = 'Tomorrow';
            } else if (day - 1 == dayForDate) {
                finalDay = 'yesterday';
            } else {
                finalDay = dateFormatter(finalDay.getTime() / 1000)
            }
        }
    }
    return finalDay;
}

// export function handleNumberFormatting(event, prevVal, decimalLength) {
//     let currentVal1 = _get(event, 'target.value', '');
//     let currentVal = currentVal1.split('.');
//     currentVal[0] = numeral(currentVal[0]).format('0,0');
//     if (currentVal[1]) {
//         if (currentVal[1].length == 1) {
//             currentVal[1] = numeral((currentVal1)).format('0.0');
//         } else {
//             currentVal[1] = numeral((currentVal1)).format('0.00');
//         }
//         currentVal[1] = currentVal[1].split('.')[1];
//     }
//     let finalNum = currentVal.join('.');
//     return finalNum;
// }
const fetchLocal=(parentId,id)=> {
const data1 = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('data')):{};
const parent_id=[]
const param_name=[]

if(data1&&data1.TabParams){
    data1.TabParams.forEach((item,i)=> {
        parent_id[item.param_value]=item.parent_id;
        param_name[item.param_value]=item.param_name;
    })
    
}
if(parentId){
return parent_id[id]
}else{
 return   param_name[id]
}
}

export function FetchLocalData(id,eqType){
    return  (fetchLocal(false,id)||"") +" - "+ (fetchLocal(false,eqType)||"")
}

export function FetchParentData(id,eqType){
    return fetchLocal(true,id)
}


export function FetchParamValue(id){
    return (fetchLocal(false,id) ||"NA")
}
export function NumToStr(originalNumber, decimalLength) {
    // if (decimalLength == 0) {
    //     return  numeral(originalNumber).format('0,0');
    // }
    let currentVal = numeral(originalNumber).format('0,0.00');
    return currentVal;
}

export function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';
  
    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }
  
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, [hasWindow]);
  
    return windowDimensions;
  }

export function assendingSort(val){
   return val.sort((a,b) => (a.displayValue.toUpperCase() > b.displayValue.toUpperCase()) ? 1 : ((b.displayValue.toUpperCase()> a.displayValue.toUpperCase()) ? -1 : 0))
}

export  function TextCapitalize(value) { 
    if(value&&value!=="NA"){
        const str=value.toString()
    if(str.toLowerCase().split("-").length>1){
      return  str.toLowerCase().split("-").map(s =>s.charAt(0).toUpperCase() + s.substring(1)).join('-');
    }else{
        return  str.toLowerCase().split(" ").map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    }
}else if(value&&value==="NA"||value===""){
    return "NA"
}else {
    return value
} 
}  
export  function TextUpperCase(value) {  
    return <span className="text-uppercase">{value}</span>
}  
export  function TextLowerCase(value) {  
    return <span className="text-lowercase">{value}</span>
}  
// export function getLocation(lat, long) {
//     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
//     Geocode.setApiKey("AIzaSyAz4zjDqzBIfW11TjZWNaTPYDCw1HxRzHg");
     
//     // set response language. Defaults to english.
//     Geocode.setLanguage("en");
     
//     // set response region. Its optional.
//     // A Geocoding request with region=es (Spain) will return the Spanish city.
//     Geocode.setRegion("es");
     
//     // Enable or disable logs. Its optional.
//     Geocode.enableDebug();
     
//     // Get address from latidude & longitude.
//     Geocode.fromLatLng(lat, long).then(
//       response => {
//         address = response.results[0].formatted_address;
//         return address;
//     },
//     error => {
//        
//     }
//     );
// }